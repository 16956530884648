import React from "react";
import Blog from "../../components/Blog";
import Par from "../../components/Par";
import SEO from "../../components/seo";

const title = "COVID-19 Planning";

export default () => (
	<Blog title={title} by="Dr Spiro Raftopoulos">
		<SEO title={title} keywords={[title]} type="article" description={title} />
		<Par>
			We have the difficult goal of continuing to care for our patients whilst doing everything possible to minimise
			spreading of the coronavirus (COVID-19).
		</Par>
		<Par>
			<b>Please note:</b>
		</Par>
		<ul className="mb-6 mt-3 leading-snug arrow">
			<li className="mb-2 relative">
				Due to low community rates of infection presently, our clinic now offers all consultations face-to-face however
				we are still able to offer consultations by Telephone or Telehealth if required
			</li>
			<li className="mb-2 relative">
				If attending for a face-to-face consultation, please notify our office if you are unwell with fever, cough or
				any respiratory symptoms and if you have had contact with anyone with confirmed coronavirus infection or
				recently returned from an interstate or overseas trip
			</li>
		</ul>
		<Par>If you are well and coming to your appointment:</Par>
		<ul className="mb-6 mt-3 leading-snug arrow">
			<li className="mb-2 relative">Please don’t bring any unnecessary people with you</li>
			<li className="mb-2 relative">Please use the hand sanitiser at the desk on arrival</li>
		</ul>

		<Par>
			Our secretarial staff are now working in the office and are available to answer and respond to any of your queries
			Monday to Friday 8 am to 5 pm by calling the practice phone on 9389 1733 or email&nbsp;
			<a href="mailto:info@advancedgiwa.com.au" className="text-primary hover:text-primary-dark hover:font-semibold">
				info@advancedgiwa.com.au
			</a>
			.
		</Par>
		<Par>
			For further information on COVID-19, please see{" "}
			<a href="https://www.healthdirect.gov.au/coronavirus" target="_blank" rel="noopener noreferrer">
				www.healthdirect.gov.au/coronavirus{" "}
			</a>
		</Par>
		<Par>We wish everyone the very best at this difficult time.</Par>
	</Blog>
);
